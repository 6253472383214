import { render, staticRenderFns } from "./login.vue?vue&type=template&id=06b439a2&scoped=true"
import script from "./login.ts?vue&type=script&lang=ts&external"
export * from "./login.ts?vue&type=script&lang=ts&external"
import style0 from "./login.vue?vue&type=style&index=0&id=06b439a2&prod&lang=css"
import style1 from "./login.vue?vue&type=style&index=1&id=06b439a2&prod&lang=less"
import style2 from "./login.vue?vue&type=style&index=2&id=06b439a2&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b439a2",
  null
  
)

export default component.exports