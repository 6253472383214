import { consoleLogsService } from "@/modules/logs/services/consoleLogsService";
import { creditiService } from "@/modules/offertaFormativa/services/creditiService";
import { Deferred } from "@/utils/Deferred";
import moment from "moment";
import settings from "@/settings";
import Vue from "vue";

class ImportaPartecipantiUtils extends Vue {
  async parsePartecipante(row: sheets.presenza, corso: corso.item, ente: ente.item, giornalisti: giornalisti.minimized[], creditiAziendali: crediti.creditiAziendali[]) {
    var defer = new Deferred<iscritto.partecipante>();
    setTimeout(() => {
      let date: Date = row.dataConfermaCrediti;
      if (typeof (row.dataConfermaCrediti) === 'string') {
        date = moment(row.dataConfermaCrediti, 'DD/MM/YYYY').toDate();
      } else if (typeof (row.dataConfermaCrediti) === 'number') {
        date = new Date(new Date(1900, 0, 1).setDate((row.dataConfermaCrediti - 1)));
      }

      let haFrequentato = true;
      let giustificato = false;
      if (row && row.haFrequentato !== null && row.haFrequentato !== undefined) {
        if (typeof (row.haFrequentato) === "string") {
          haFrequentato = row.haFrequentato.toLowerCase() === "si"
          giustificato = row.haFrequentato.toLowerCase() === "giustificato"
        } else {
          haFrequentato = row.haFrequentato === 1;
        }
      }

      let giornalista: giornalisti.minimized = null;
      if (row.codiceFiscale && (giornalisti || []).length > 0) {
        giornalista = giornalisti.find(f => f.codiceFiscale.toUpperCase() === row.codiceFiscale.toUpperCase());
      }

      let parseRow = {
        anagraficaGiornalistaId: giornalista?.id,
        nomeCompleto: giornalista?.nomeCompleto,
        mail: giornalista?.mail,
        codiceFiscale: giornalista?.codiceFiscale || row.codiceFiscale,
        corsoId: corso.id,
        dataIscrizione: corso.data,
        dataAmmissione: corso.data,
        ammesso: true,
        dataCancellazione: null,
        cancellato: false,
        motivazioneCancellazione: null,
        dataConfermaCrediti: corso.continuativo ? date : corso.data,
        haFrequentato: haFrequentato,
        giustificato: giustificato,
        dateOutOfRange: false,
        duplicated: false,
        valid: false,
        edit: false,
      } as iscritto.partecipante;

      defer.resolve(this.checkPresenza(parseRow, giornalisti, corso, ente, creditiAziendali));
    });
    return defer.promise;
  }

  checkPresenza(row: iscritto.partecipante, giornalisti: giornalisti.minimized[], corso: corso.item, ente: ente.item, creditiAziendali: crediti.creditiAziendali[]) {
    let giornalista: giornalisti.minimized = null;
    if (row.codiceFiscale && (giornalisti || []).length > 0) {
      giornalista = giornalisti.find(f => f.codiceFiscale.toUpperCase() === row.codiceFiscale.toUpperCase());
    }

    row.duplicated = false;
    row.anagraficaGiornalistaId = giornalista?.id || null;
    row.nomeCompleto = giornalista?.nomeCompleto || null;
    row.mail = giornalista?.mail || null;
    row.contatoreBlacklist = giornalista?.contatoreBlacklist;
    row.dataIngressoBlacklist = giornalista?.dataIngressoBlacklist;
    row.creditiAziendali = creditiAziendali.find(f => f.anagraficaGiornalistaId === row.anagraficaGiornalistaId)?.totale ?? 0;

    row.valid = !!row.codiceFiscale
      && !!row?.anagraficaGiornalistaId
      && !!row.dataConfermaCrediti
      && !(row.haFrequentato && row.giustificato)
      && (ente && ente.tipo === "azienda" ? !((row.creditiAziendali + corso.creditiDisponibili) > 30) : true)
      && !this.checkGiornalistaInBlacklist(giornalista, corso, row);

    return row;
  }

  checkGiornalistaInBlacklist(giornalista: giornalisti.minimized, corso: corso.item, row: iscritto.partecipante) {
    const dataIngressoBlacklist = giornalista?.dataIngressoBlacklist;
    if (!dataIngressoBlacklist) return false;

    const dataUscitaBlacklist = new Date(dataIngressoBlacklist.getTime() + (settings.appSetting.blacklist.durataInGiorni * 24 * 60 * 60 * 1000))

    const d = corso.continuativo ? row.dataConfermaCrediti : corso.data;
    const dataConfermaCrediti: Date = typeof (d) === 'string' ? new Date(d) : d;

    return row.contatoreBlacklist >= settings.appSetting.blacklist.assenzePerIngresso
      && dataIngressoBlacklist.setHours(0, 0, 0, 0) <= dataConfermaCrediti.setHours(0, 0, 0, 0)
      && dataUscitaBlacklist.setHours(0, 0, 0, 0) >= dataConfermaCrediti.setHours(0, 0, 0, 0)
  }

  normalize(value: string | number): string {
    if (!value) return null;

    if (typeof value === "number") {
      value = value.toString();
    }
    return value
      .toString()
      .toLowerCase()
      .replace(/ /g, "_")
      .replace(/-/g, "_");
  }

  // processLargeArrayAsync(array: any[], chunkFn: Function, endFn?: Function, chunk?: number, context?: any) {
  //   context = context || window;
  //   chunk = chunk || 100;
  //   var index = 0;
  //   function doChunk() {
  //     var cnt = chunk;
  //     while (cnt-- && index < array.length) {
  //       // callback called with args (value, index, array)
  //       chunkFn.call(context, array[index], index, array);
  //       ++index;
  //     }
  //     if (index < array.length) {
  //       // set Timeout for async iteration
  //       setTimeout(doChunk, 1);
  //     } else {
  //       if (endFn)
  //         endFn.call(context);
  //     }
  //   }
  //   doChunk();
  // }
}

export const importaPartecipantiUtils = new ImportaPartecipantiUtils();